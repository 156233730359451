<template>
  <div class="uk-margin-top">
    <div class="uk-card uk-card-default uk-padding-small kandang-card">
      <FilterRoles />
      <TableRoles />
    </div>
  </div>
</template>
<script>
import FilterRoles from './filter'
import TableRoles from './table'

export default {
  components: {
    FilterRoles,
    TableRoles
  }
}
</script>
