<template>
  <div class="uk-margin-top">
    <MainTable :table-props="tableProps">
      <thead class="thead-table-paranje">
        <tr>
          <th class="column-title uk-width-small">
            <span class="uk-text-bold">Kode Barang</span>
          </th>
          <th class="column-title uk-width-medium">
            <span class="uk-text-bold">Nama Barang</span>
          </th>
          <th class="column-title uk-table-expand">
            <span class="uk-text-bold">Terakhir Diubah</span>
          </th>
        </tr>
      </thead>
      <template v-if="!is_loading">
        <tbody v-if="merkDocs.meta.total_count>0">
          <tr
            v-for="(doc, i) in merkDocs.data"
            :key="i"
          >
            <td>{{ doc.code || '-' }}</td>
            <td>{{ doc.name || '-' }}</td>
            <td>{{ doc.updated_at ? formatDate(doc.updated_at) : '-' }}</td>
          </tr>
        </tbody>
        <empty-table
          v-else
          :colspan="10"
        />
      </template>
      <template v-else>
        <loading-table :colspan="10" />
      </template>
    </MainTable>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import MainTable from '@/components/globals/table'
import EmptyTable from '@/components/globals/table/EmptyTable'
import LoadingTable from '@/components/globals/table/LoadingTable'
import { dateUtcParanjeString } from '@/utils/formater'

export default {
  components: {
    EmptyTable,
    LoadingTable,
    MainTable
  },
  data() {
    return {
      is_loading: true,
      tableProps: {
        total_count: 0
      }
    }
  },
  computed : {
    ...mapGetters({
      merkDocs: 'merkDoc/merkDocs',
      meta: 'merkDoc/meta'
    })
  },
  watch: {
    async meta() {
      this.is_loading = true
      await this.getMerkDoc({...this.meta, by_item_category: 'doc'})
      this.is_loading = false
    },
    merkDocs() {
      if (this.merkDocs) {
        if (this.merkDocs.data) {
          this.tableProps ={
            ...this.meta,
            meta: this.meta,
            setMeta: this.setMeta,
            total_count: this.merkDocs.meta.total_count
          }
        }
      }
    }
  },
  async mounted() {
    await this.getMerkDoc({...this.meta, by_item_category: 'doc'})
    this.is_loading = false
  },
  methods: {
    formatDate(date) {
      return dateUtcParanjeString(date)
    },
    ...mapActions({
      getMerkDoc: 'merkDoc/getMerkDoc'
    }),
    ...mapMutations({
      setMeta: 'merkDoc/SET_META'
    })
  }
}
</script>

<style scoped>
.column-title {
  vertical-align: inherit;
}
</style>
