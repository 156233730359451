<template>
  <div>
    <div
      style="border-bottom: none"
      class="uk-form-horizontal filter-container"
    >
      <div
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-first-column">
          <label
            class="uk-form-label filter-margin"
            for="form-horizontal-text"
          >Cari</label>
          <div class="uk-form-controls">
            <input
              id="form-horizontal-text"
              v-model="metaFilter.name"
              class="uk-input"
              type="text"
              placeholder="Cari kode/nama barang"
            >
          </div>
        </div>
      </div>
      <div
        class="filter-margin uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-1@l uk-text-right">
          <button
            class="uk-button uk-button-default uk-width-expand uk-width-auto@s"
            @click="clearFilter()"
          >
            <span
              uk-icon="icon: close; ratio: 0.9"
              class="uk-margin-small-right"
            />Clear
          </button>
          <button
            class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
            @click="handleFind()"
          >
            <span
              uk-icon="icon: search; ratio: 0.9"
              class="uk-margin-small-right"
            />Cari
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex"

export default {
  data() {
    return {
      metaFilter: {
        name: ""
      }
    }
  },
  computed: {
    ...mapGetters({
      meta: "merkDoc/meta"
    })
  },
  methods: {
    ...mapMutations({
      setMeta: "merkDoc/SET_META"
    }),
    handleFind() {
      this.metaFilter.page = 1
      this.setMeta({
        ...this.meta,
        ...this.metaFilter
      })
    },
    clearFilter() {
      this.metaFilter.name = ""
      this.handleFind()
    }
  }
}
</script>
